// import { ArrowNarrowLeftOutline } from '@graywolfai/react-heroicons'
import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, ReactNode, useEffect, useState } from 'react'
import appMeta from '../configs/appMeta'
import IconLoading from './IconLoading'

interface Props {
  title: ReactNode
  description?: ReactNode
}

export const AuthLayout: FC<Props> = ({ title, description, children }) => {
  const [historyLength, setHistoryLength] = useState<number>()
  const router = useRouter()
  useEffect(() => {
    setHistoryLength(history.length)
  }, [])
  return (
    <>
      <Head>
        <title>{[appMeta.name, title].join(` ${appMeta.separator} `)}</title>
      </Head>
      <div className="bg-gray-50 text-gray-600 min-h-screen">
        <div className="px-4 sm:px-6">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col items-center py-4 sm:py-12">
              <div className="max-w-md w-full space-y-8">
                <div className="flex justify-start">
                  {historyLength ? (
                    <a className="font-medium text-primary-600 hover:text-primary-500 flex items-center space-x-1 cursor-pointer"
                      onClick={() => router.back()}
                    >
                      {/* <ArrowNarrowLeftOutline className="w-6 h-6" /> */}
                      <ArrowNarrowLeftIcon className="w-6 h-6" />
                      <span>Back</span>
                    </a>
                  ) : (
                    <Link href="/">
                      <a className="font-medium text-primary-600 hover:text-primary-500 flex items-center space-x-1">
                        {/* <ArrowNarrowLeftOutline className="w-6 h-6" /> */}
                        <ArrowNarrowLeftIcon className="w-6 h-6" />
                        <span>Home</span>
                      </a>
                    </Link>
                  )}
                </div>
                <div className="text-center">
                  <Link href="/">
                    <a className="inline-block">
                      <Image src="/static/images/sign-in.jpg"
                        objectFit="contain"
                        width={96}
                        height={96}
                        alt={appMeta.name}
                      />
                    </a>
                  </Link>
                </div>
                <div>
                  <h2 className="text-center text-3xl font-extrabold text-gray-900">{title}</h2>
                  {description ? (
                    <p className="mt-2 text-center text-sm">
                      {description}
                    </p>
                  ) : null}
                </div>
                {children ? (
                  <div className="mt-8 bg-white rounded-lg shadow-md py-4 sm:py-8 px-4 sm:px-12">
                    {children}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

interface UnauthorizedProps {
  redirect?: string
}

export const Unauthorized: FC<UnauthorizedProps> = ({ redirect }) => {
  const router = useRouter()
  return (
    <AuthLayout title="You are not signed in" description={(
      <span>Please <Link href={`/auth/sign-in?redirect=${redirect ?? router.asPath}`}>
        <a className="font-medium text-primary-600 hover:text-primary-500">sign in</a>
      </Link> to continue</span>
    )} />
  )
}

export const Forbidden: FC = () => {
  const [historyLength, setHistoryLength] = useState<number>()
  const router = useRouter()
  useEffect(() => {
    setHistoryLength(history.length)
  }, [])
  return (
    <AuthLayout title="You are not allowed to access this page" description={(
      <span>{historyLength ? (
        <a className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer" onClick={() => router.back()}>Go back</a>
      ) : (
        <Link href="/">
          <a className="font-medium text-primary-600 hover:text-primary-500">Go back</a>
        </Link>
      )} to previous page or <Link href="/"><a className="font-medium text-primary-600 hover:text-primary-500">go to home page</a></Link></span>
    )} />
  )
}

export const NotFound: FC = () => {
  const [historyLength, setHistoryLength] = useState<number>()
  const router = useRouter()
  useEffect(() => {
    setHistoryLength(history.length)
  }, [])
  return (
    <AuthLayout title="Page not found" description={(
      <span>{historyLength ? (
        <a className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer" onClick={() => router.back()}>Go back</a>
      ) : (
        <Link href="/">
          <a className="font-medium text-primary-600 hover:text-primary-500">Go back</a>
        </Link>
      )} to previous page or <Link href="/"><a className="font-medium text-primary-600 hover:text-primary-500">go to home page</a></Link></span>
    )} />
  )
}

export const AuthLoading: FC = () => {
  return (
    <div className="bg-gray-50 text-gray-600 min-h-screen">
      <div className="px-4 sm:px-6">
        <div className="max-w-7xl mx-auto">
          <div className="min-h-screen flex items-center justify-center">
            <h1 className="flex space-x-2 font-medium text-lg leading-normal align-middle items-center">
              <IconLoading className="w-6 h-6 text-primary-600 animate-spin" />
              <span>Loading...</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}