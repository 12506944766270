import { Account, Prisma } from '.prisma/client'
// import { PlusOutline } from '@graywolfai/react-heroicons'
import { PlusIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import { AuthLoading } from '../components/Auth'
import useAuth from '../hooks/useAuth'
import { buildURL } from '../libs/swrHelpers'
import { Page } from '../types/page'

const Home: Page = () => {
  const auth = useAuth()
  const router = useRouter()
  // const accounts = useSWR<Account[]>(buildURL<Prisma.AccountFindManyArgs>('/api/accounts'))

  if (!auth.isReady) {
    return <AuthLoading />
  }

  if (!auth.user) {
    router.push('/auth/sign-in')
    return <AuthLoading />
  } else {
    router.push('/dashboard')
    return <AuthLoading />
  }
}

export default Home